// @generated by protoc-gen-es v1.10.0
// @generated from file hmm/ninja/panels_service.proto (package hmm.ninja, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Panel, PanelConfig } from "./panels_pb.js";

/**
 * @generated from message hmm.ninja.ListPanelsRequest
 */
export const ListPanelsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.ninja.ListPanelsRequest",
  [],
);

/**
 * @generated from message hmm.ninja.ListPanelsResponse
 */
export const ListPanelsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.ninja.ListPanelsResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: Panel, repeated: true },
  ],
);

/**
 * @generated from message hmm.ninja.ListPanelConfigsRequest
 */
export const ListPanelConfigsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.ninja.ListPanelConfigsRequest",
  () => [
    { no: 3, name: "survey_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "panel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message hmm.ninja.ListPanelConfigsResponse
 */
export const ListPanelConfigsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.ninja.ListPanelConfigsResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: PanelConfig, repeated: true },
  ],
);

/**
 * @generated from message hmm.ninja.CreatePanelConfigRequest
 */
export const CreatePanelConfigRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.ninja.CreatePanelConfigRequest",
  () => [
    { no: 1, name: "panel_config", kind: "message", T: PanelConfig },
    { no: 2, name: "skip_purchase_order_alignment", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message hmm.ninja.CreatePanelConfigResponse
 */
export const CreatePanelConfigResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.ninja.CreatePanelConfigResponse",
  () => [
    { no: 1, name: "panel_config", kind: "message", T: PanelConfig },
  ],
);

/**
 * @generated from message hmm.ninja.GetPanelConfigRequest
 */
export const GetPanelConfigRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.ninja.GetPanelConfigRequest",
  () => [
    { no: 1, name: "survey_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "panel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message hmm.ninja.GetPanelConfigResponse
 */
export const GetPanelConfigResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.ninja.GetPanelConfigResponse",
  () => [
    { no: 1, name: "panel_config", kind: "message", T: PanelConfig },
  ],
);

/**
 * @generated from message hmm.ninja.UpdatePanelConfigRequest
 */
export const UpdatePanelConfigRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.ninja.UpdatePanelConfigRequest",
  () => [
    { no: 1, name: "panel_config", kind: "message", T: PanelConfig },
    { no: 2, name: "skip_purchase_order_alignment", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message hmm.ninja.UpdatePanelConfigResponse
 */
export const UpdatePanelConfigResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.ninja.UpdatePanelConfigResponse",
  () => [
    { no: 1, name: "panel_config", kind: "message", T: PanelConfig },
  ],
);

/**
 * @generated from message hmm.ninja.DeletePanelConfigRequest
 */
export const DeletePanelConfigRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.ninja.DeletePanelConfigRequest",
  () => [
    { no: 1, name: "panel_config", kind: "message", T: PanelConfig },
  ],
);

/**
 * @generated from message hmm.ninja.DeletePanelConfigResponse
 */
export const DeletePanelConfigResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.ninja.DeletePanelConfigResponse",
  [],
);

