// @generated by protoc-gen-es v1.10.0
// @generated from file hmm/ninja/panels.proto (package hmm.ninja, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Money } from "../../google/type/money_pb.js";

/**
 * @generated from message hmm.ninja.Panel
 */
export const Panel = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.ninja.Panel",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "src", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message hmm.ninja.PanelConfig
 */
export const PanelConfig = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.ninja.PanelConfig",
  () => [
    { no: 1, name: "panel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "survey_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "status", kind: "enum", T: proto3.getEnumType(PanelConfig_Status) },
    { no: 12, name: "finance_entity_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "required_sample", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "redirect_tokens", kind: "message", T: PanelConfig_RedirectTokens },
    { no: 5, name: "distribution_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "contact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "reference", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "po", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "forecast_cpi", kind: "message", T: Money },
    { no: 30, name: "forecast_fees", kind: "message", T: Money },
    { no: 31, name: "forecast_cost", kind: "message", T: Money },
    { no: 35, name: "invoiced_cpi", kind: "message", T: Money },
    { no: 40, name: "invoiced_fees", kind: "message", T: Money },
    { no: 41, name: "invoiced_cost", kind: "message", T: Money },
    { no: 45, name: "invoice_paid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 46, name: "bill_received", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from enum hmm.ninja.PanelConfig.Status
 */
export const PanelConfig_Status = /*@__PURE__*/ proto3.makeEnum(
  "hmm.ninja.PanelConfig.Status",
  [
    {no: 0, name: "STATUS_UNKNOWN", localName: "UNKNOWN"},
    {no: 10, name: "STATUS_FORECAST", localName: "FORECAST"},
    {no: 20, name: "STATUS_REPORTED", localName: "REPORTED"},
  ],
);

/**
 * @generated from message hmm.ninja.PanelConfig.RedirectTokens
 */
export const PanelConfig_RedirectTokens = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.ninja.PanelConfig.RedirectTokens",
  () => [
    { no: 1, name: "quota_full", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "screen_out", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "complete", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "PanelConfig_RedirectTokens"},
);

