// @generated by protoc-gen-connect-es v1.6.1
// @generated from file hmm/ninja/panels_service.proto (package hmm.ninja, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreatePanelConfigRequest, CreatePanelConfigResponse, DeletePanelConfigRequest, DeletePanelConfigResponse, GetPanelConfigRequest, GetPanelConfigResponse, ListPanelConfigsRequest, ListPanelConfigsResponse, ListPanelsRequest, ListPanelsResponse, UpdatePanelConfigRequest, UpdatePanelConfigResponse } from "./panels_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service hmm.ninja.PanelsService
 */
export const PanelsService = {
  typeName: "hmm.ninja.PanelsService",
  methods: {
    /**
     * List all available panels.
     *
     * @generated from rpc hmm.ninja.PanelsService.ListPanels
     */
    listPanels: {
      name: "ListPanels",
      I: ListPanelsRequest,
      O: ListPanelsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListPanelConfigs.
     *
     * @generated from rpc hmm.ninja.PanelsService.ListPanelConfigs
     */
    listPanelConfigs: {
      name: "ListPanelConfigs",
      I: ListPanelConfigsRequest,
      O: ListPanelConfigsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreatePanelConfig.
     *
     * @generated from rpc hmm.ninja.PanelsService.CreatePanelConfig
     */
    createPanelConfig: {
      name: "CreatePanelConfig",
      I: CreatePanelConfigRequest,
      O: CreatePanelConfigResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetPanelConfig gets a single suvey panel config.
     *
     * @generated from rpc hmm.ninja.PanelsService.GetPanelConfig
     */
    getPanelConfig: {
      name: "GetPanelConfig",
      I: GetPanelConfigRequest,
      O: GetPanelConfigResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdatePanelConfig.
     *
     * @generated from rpc hmm.ninja.PanelsService.UpdatePanelConfig
     */
    updatePanelConfig: {
      name: "UpdatePanelConfig",
      I: UpdatePanelConfigRequest,
      O: UpdatePanelConfigResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DeletePanelConfig.
     *
     * @generated from rpc hmm.ninja.PanelsService.DeletePanelConfig
     */
    deletePanelConfig: {
      name: "DeletePanelConfig",
      I: DeletePanelConfigRequest,
      O: DeletePanelConfigResponse,
      kind: MethodKind.Unary,
    },
  }
};

